<script>

import ArtworkThumb from './ArtworkThumb.svelte';
import { db } from './firebase';
import Footer from './Footer.svelte';
import { collection } from './config';
import Navbar from "./Navbar.svelte";

const getDefaultArtwork = async () => {
    const doc = await db.collection(collection).doc('default').get();
    return { ...doc.data(), id: doc.id };
}; 

const getArtworks = async () => {
    const snapshot = await db.collection(collection)
        .where('fileName', '!=', 'default.jpg').get();
    return snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
};

const shuffle = (array) => {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

</script>

<style>
.intro {
    padding: .5em .8em;
    border-bottom: 1px solid #ccc;
}

.intro p {
    margin-block-start: .6em;
    margin-block-end: .6em;
}

.artworks {
    display: flex;
    flex-flow: wrap;
}

@media (min-width: 43em) {
    .intro {
        display: flex;
        justify-content: space-between;
    }
}
</style>

<Navbar />

<div class="intro">
    <p>Schleiffen Limited Edition</p>
    <p>66 unique artworks</p>
    <p>thanks to all the artists ❤</p>
</div>

<div class="artworks">

    {#await getDefaultArtwork() then defaultArtwork}
        <ArtworkThumb {...defaultArtwork} />
    {/await}
    
    {#await getArtworks() then artworks}
        {#each shuffle(artworks) as artwork}
            <ArtworkThumb {...artwork} />
        {/each}
    {/await}

</div>

<Footer />
