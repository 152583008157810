<script>

import { Link } from 'svelte-routing';
import FaBars from 'svelte-icons/fa/FaBars.svelte'

</script>

<style>

nav {
    border-bottom: 1px solid black;
    background-color: white;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    position: sticky;
    padding: .3em .6em;
}

.title {
    flex-grow: 1;
    text-align: right;
    font-size: 1.2em;
}

.ham-link {
    width: 20px;
    height: 20px;
}

@media (min-width: 43em) {

    nav {
        padding: 1em 1.5em;
    }

    .ham-link {
        width: 40px;
        height: 40px;
    }

    .title {
        font-size: 3em;
        flex-grow: 1;
        text-align: right;
    }

}

</style>

<nav>
    <div class="ham-link">
        <Link to="/social">
            <FaBars />
        </Link>
    </div>
    <div class="title">DHRESEN</div>
</nav>
