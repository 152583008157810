import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAtbHjndRiMgQHx3SP24W0EMp2za_w3ZSc",
    authDomain: "dhresen-6e5d3.firebaseapp.com",
    projectId: "dhresen-6e5d3",
    storageBucket: "dhresen-6e5d3.appspot.com",
    messagingSenderId: "118441721500",
    appId: "1:118441721500:web:3b4b8db3b2a5bf8a50b759",
    measurementId: "G-2VDSLCFESL"
  };

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();

export const db = firebase.firestore();
export const storage = firebase.storage();
