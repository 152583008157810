<svelte:head>
    <title>Dhresen - Schleifen / Limited Edition</title>
</svelte:head>

<script>

import { storage } from './firebase';
import { db } from './firebase';
import { Link } from 'svelte-routing';
import Spinner from './Spinner.svelte';
import { collection } from './config';
import MdClose from 'svelte-icons/md/MdClose.svelte'
import GiShoppingCart from 'svelte-icons/gi/GiShoppingCart.svelte'

export let id;

let frame = false;

const getArtwork = async (id) => (await db.collection(collection).doc(id).get()).data();

const getImgUrl = async (fileName) => await storage.ref(fileName).getDownloadURL();

const getDoc = async () => {
    const doc = await getArtwork(id);
    const imgUrl = await getImgUrl(doc.fileName);
    return { ...doc, imgUrl };
}

</script>

<style>
.container {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.container.frame {
    background-color: black;
    color: white;
}

.frame a,
:global(.frame .link) {
    color: white;
}

.image-container {
    position: relative;
}

img {
    display: block;
    max-width: 100vw;
    max-height: 100vh;
    width: auto;
    height: auto;
}

.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: .3em;
    font-size: 1em;
}

.left button {
    border: 0;
    border-radius: 0;
    height: 1.8em;
    width: 1.8em;
    padding: 0;
    margin: 0;
    background-color: black;
    color: white;
}

.square {
    background-color: white;
    width: .9em;
    height: .9em;
    margin: .45em;
}

.frame .square {
    background-color: black;
}

.frame .left button {
    background-color: white;
    color: black;
}

.right {
    text-align: right;
}

.sub {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: .85em;
}

.sub .left {
    padding: .3em .6em;
}

.sub .right {
    padding: .3em .6em;
    background-color: rgba(255,255,255,.6);
}

.frame .sub .right {
    background-color: rgba(0,0,0,.6);
}

.grey {
    color: #999;
}

.image-frame {
    display: none;
}

.frame .image-frame {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 26vw solid white;
    box-sizing: border-box;
}

.sold {
    color: red;
}

.icon {
    width: 28px;
}

@media (min-width: 43em) {
    .frame .image-frame {
        border-width: 26vh;
    }
}
</style>

<div class="container" class:frame={frame}>
    {#await getDoc()}
        <Spinner />
    {:then doc}
        <div class="head">
            <div class="left">
                <button on:click={() => frame = !frame}>
                    <div class="square"></div>
                </button>
            </div>
            <div class="right">
                <div class="icon">
                    <Link to="/" class="link">
                        <MdClose />
                    </Link>
                </div>
            </div>
        </div>
        <div class="image-container">
            <img src={doc.imgUrl} alt="Artwork">
            <div class="image-frame"></div>
        </div>
        <div class="sub">
            <div class="left">
                {#if doc.sold}
                    <span class="sold">sold</span>
                {:else}
                    <a href={`https://docs.google.com/forms/d/e/1FAIpQLSfadQpAwDQBxnFBsZHnDWmKLStwei5MEds1rAD5P2mwN3tydA/viewform?usp=pp_url&entry.1105815176=${id}`} target="_blank">
                        <div class="icon">
                            <GiShoppingCart />
                        </div>
                    </a>
                {/if}
            </div>
            <div class="right">
                {#if id === 'default'}
                    <span class="grey">Regular Edition</span>
                {/if}
                Artist:
                {#if doc.artistLink}
                    <a href={doc.artistLink} style="text-decoration: underline;">{doc.artist}</a>
                {:else}
                    {doc.artist}
                {/if}
                {#if doc.info},
                    {doc.info}
                {/if}
                {#if id === 'default'}
                    - <span class="grey">18€</span>
                {:else}
                    - <span class="grey">30€</span>
                {/if}
            </div>
        </div>
    {/await}
</div>
