<style>
.footer {
    padding: 3em;
    text-align: center;
    border-top: 1px solid black;
}
</style>

<div class="footer">
    <p>IMPRESSUM</p>
    <p>Dhresen<br>
    Schirmerstr. 40<br>
    50823 Köln</p>
    <p>dhresen[at]web.de</p>
</div>
