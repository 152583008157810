<script>

import { Link } from 'svelte-routing';
import MdClose from 'svelte-icons/md/MdClose.svelte'
import FaBandcamp from 'svelte-icons/fa/FaBandcamp.svelte'
import FaInstagram from 'svelte-icons/fa/FaInstagram.svelte'
import FaSpotify from 'svelte-icons/fa/FaSpotify.svelte'
import FaFacebook from 'svelte-icons/fa/FaFacebook.svelte'
import FaYoutube from 'svelte-icons/fa/FaYoutube.svelte'

</script>

<style>
.container {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: .3em;
    font-size: 1em;
}

.icon {
    width: 28px;
}

.social-icons {
    display: flex;
}

.social-icon {
    width: 30px;
    padding: 1em;
}
</style>

<div class="container">
    <div class="head">
        <div class="right">
            <div class="icon">
                <Link to="/" class="link">
                    <MdClose />
                </Link>
            </div>
        </div>
    </div>
    <div class="social-icons">
        <div class="social-icon">
            <a href="https://dhresen.bandcamp.com" target="_blank">
                <FaBandcamp />
            </a>
        </div>
        <div class="social-icon">
            <a href="https://open.spotify.com/artist/4c3kTtpa6arzzfY1P5eGEv?si=vAppcTv-Trq2L_xQthZQlg" target="_blank">
                <FaSpotify />
            </a>
        </div>
        <div class="social-icon">
            <a href="https://www.youtube.com/channel/UCjUKf4Ba5JUMjkRXuW9KbgQ" target="_blank">
                <FaYoutube />
            </a>
        </div>
        <div class="social-icon">
            <a href="https://instagram.com/dhresen" target="_blank">
                <FaInstagram />
            </a>
        </div>
        <div class="social-icon">
            <a href="https://www.facebook.com/dhresen" target="_blank">
                <FaFacebook />
            </a>
        </div>
    </div>
</div>
