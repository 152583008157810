<script>

import { storage } from './firebase';
import { Link } from 'svelte-routing';
import ImageLoader from './ImageLoader.svelte';

export let id;
export let fileName;
export let artist;
export let artistLink;
export let sold;
export let info;

const getThumbUrl = async (fileName) => await storage.ref(`thumb_${fileName}`).getDownloadURL();

</script>

<style>

.container {
    width: 100vw;
    height: 100vw;
    position: relative;
}

.frame {
    display: none;
}

.sub {
    display: none;
}

.grey {
    color: #999;
}

@media (min-width: 43em) {

    .container {
        height: 0;
        width: 50%;
        padding-bottom: 50%;
    }

    .container:hover .frame {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        border: 12.5vw solid white;
    }

    .container:hover .sub {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: .3em .5em;
        font-size: .85em;
        text-align: right;
        background-color: white;
    }

}

@media (min-width: 800px) {
    .container {
        width: 33.33%;
        padding-bottom: 33.33%;
    }
    .container:hover .frame {
        border: 8.33vw solid white;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 25%;
        padding-bottom: 25%;
    }
    .container:hover .frame {
        border: 6.25vw solid white;
    }
}
@media (min-width: 1600px) {
    .container {
        width: 20%;
        padding-bottom: 20%;
    }
    .container:hover .frame {
        border: 5vw solid white;
    }
}
@media (min-width: 2000px) {
    .container {
        width: 16.66%;
        padding-bottom: 16.66%;
    }
    .container:hover .frame {
        border: 4.16vw solid white;
    }
}
</style>

<div class="container">
    {#await getThumbUrl(fileName) then imgUrl}
        <Link to="/artwork/{id}">
            <ImageLoader src={imgUrl} alt={`Artwork by ${artist}`} />
            <div class="frame"></div>
            <div class="sub">
                {#if id === 'default'}
                    <span class="grey">Regular Edition</span>
                {/if}
                Artist:
                {#if artistLink}
                    <a href={artistLink}>{artist}</a>
                {:else}
                    {artist}
                {/if}
            </div>
        </Link>
    {/await}
</div>
