<script>

import { Router, Route } from "svelte-routing";
import ArtworkFullscreen from "./ArtworkFullscreen.svelte";
import Artworks from "./Artworks.svelte";
import Social from "./Social.svelte";
import Start from "./Start.svelte";

export let url = "";

</script>

<Router {url}>
	<Route path="schleiffen"><Artworks /></Route>
	<Route path="schleiffen/artwork/:id" component="{ArtworkFullscreen}" />
	<Route path="social" component="{Social}" />
	<Route path="/" component="{Start}" />
</Router>
