<script>
  import Navbar from "./Navbar.svelte";
  import Footer from "./Footer.svelte";
  import Link from "svelte-routing/src/Link.svelte";
  import Image from "./Image.svelte";
</script>

<style>
    .container {
        display: flex;
        padding: 1em;
        flex-direction: column;
    }
    .container div {
        padding: 1em;
        text-align: center;
        font-size: 1.2em;
    }
    .container div:hover {
        opacity: 0.9;
    }
    @media (min-width: 800px) {
        .container {
            padding: 2em;
            flex-direction: row;
        }
        .container div {
            padding: 2em;
        }
    }
</style>

<Navbar />

<div class="container">
    <div>
        <a href="https://dhresen.bandcamp.com/album/reiffen" target="_blank">
            <Image src="/reiffen.jpg" />
            <p><b>Reiffen</b> &#183; <i>March 2023</i></p>
        </a>
    </div>
    <div>
        <Link to="/schleiffen">
            <Image src="/schleiffen.jpg" />
            <p><b>Schleiffen</b> &#183; <i>December 2020</i></p>
        </Link>
    </div>
</div>

<Footer />