<script>

import { onMount } from "svelte";

export let once = false;
export let top = 0;
export let bottom = 0;
export let left = 0;
export let right = 0;

let intersecting = false;
let container;

onMount(() => {
    if (typeof IntersectionObserver !== "undefined") {
        const rootMargin = `${bottom}px ${left}px ${top}px ${right}px`;

        const observer = new IntersectionObserver(
            (entries) => {
                intersecting = entries[0].isIntersecting;
                if (intersecting && once) {
                    observer.unobserve(container);
                }
            },
            {
                rootMargin,
            }
        );

        observer.observe(container);
        return () => observer.unobserve(container);
    }

    // The following is a fallback for older browsers
    function handler() {
        const bcr = container.getBoundingClientRect();

        intersecting =
            bcr.bottom + bottom > 0 &&
            bcr.right + right > 0 &&
            bcr.top - top < window.innerHeight &&
            bcr.left - left < window.innerWidth;

        if (intersecting && once) {
            window.removeEventListener("scroll", handler);
        }
    }

    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
});

</script>

<style>
div {
    width: 100%;
    height: 100%;
}
</style>

<div bind:this={container}>
    <slot {intersecting} />
</div>
